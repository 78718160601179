import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Élagage // Abattage // Arboriste // Grimpeur-Élagueur, taille et
            soins des arbres // Belgique
          </title>
          <meta
            name="description"
            content="Grimpeur-élagueur à Bruxelles, technicien spécialisé dans la taille raisonnée et le soin des arbres."
          />
        </Helmet>

        <section id="banner" className="major">
          <div className="inner">
            <header className="major">
              <h1>Grimpeur-Élagueur à Bruxelles</h1>
            </header>
            <h3>Nicolas Deketelaere</h3>
            <div className="content">
              <p>
                Technicien spécialisé dans la taille raisonnée
                <br /> et le soin des arbres.
              </p>
            </div>
            <ul className="actions">
              <li>
                <a href="#contact" className="button special">
                  Contact
                </a>
              </li>
              <li>
                <a href="#services" className="button next">
                  Services
                </a>
              </li>
            </ul>
          </div>
        </section>

        <main id="main">
          <section id="services" className="spotlights">
            <section>
              <StaticImage
                src="../assets/images/pic17.jpg"
                alt=""
                width={800}
              />
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Conseils et diagnostics</h3>
                  </header>
                  <p>
                    Agir de manière avisée et précautionneuse de l'écosystème.
                    <br />
                    <small className="small">
                      L’intervention dans un arbre doit toujours tendre à un
                      objectif. Il est important d’en mesurer tous les aspects,
                      d’évaluer les risques et de les minimiser. En abordant
                      l’arbre dans sa globalité, différentes solutions peuvent
                      souvent être envisagées. L’analyse préalable d’une
                      situation garantit le bon déroulement du chantier et
                      promet des conditions optimales de sécurité. Une
                      planification adaptée, un délai de réalisation qui prend
                      en compte l’ensemble des contraintes, un devis clair
                      rédigé en conséquence sont les gages du bon déroulement
                      des travaux.
                    </small>
                  </p>
                </div>
              </div>
            </section>

            <section>
              <StaticImage
                className="image"
                src="../assets/images/pic18.jpg"
                alt=""
              />
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Taille</h3>
                  </header>
                  <p>
                    Taille d'ornement, taille fruitière, taille de haies, etc.
                    <br />
                    <small className="small">
                      L’entretien des arbres et des arbustes passe par la
                      taille, qui permet de renforcer la composition paysagère.
                      Taille de haie, formation en fuseau, en rideau, en
                      marquise, en voûte, en tonnelle... sont de nombreux types
                      de taille qui permettent de cohabiter avec la nature en
                      toute proximité. La diversité des espèces est si vaste
                      qu’elle nous permet de trouver des solutions qui
                      s’adaptent tout naturellement à nos exigences et à nos
                      besoins.
                    </small>
                  </p>
                </div>
              </div>
            </section>

            <section>
              <StaticImage
                className="image"
                src="../assets/images/pic19.jpg"
                alt=""
              />
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Travaux acrobatiques divers</h3>
                  </header>
                  <p>
                    Tâches à accès difficile
                    <br />
                    <small className="small">
                      Quelques exemples: sauvetage des chats dans les arbres,
                      suppression d'anciennes conduites, traitement contre les
                      guêpes et frelons, tous travaux d’accès difficile ou en
                      hauteur...
                    </small>
                  </p>
                </div>
              </div>
            </section>

            <section>
              <StaticImage
                className="image"
                src="../assets/images/pic04.jpg"
                alt=""
              />
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Haubanage et Tuteurage</h3>
                  </header>
                  <p>
                    Guider et soutenir pour sécuriser.
                    <br />
                    <small className="small">
                      Les arbres subissent torsion, flexion, tension sous
                      l’influence des vents et des mouvements de sol. Le
                      haubanage est destiné à renforcer la stabilité mécanique
                      dans la couronne de l’arbre. L’intérêt de recourir à cette
                      technique est de limiter les tailles drastiques dans notre
                      patrimoine arboré et de limiter la chute de branches en
                      cas de rupture. Cette solution est douce et non
                      traumatisante pour l’arbre si elle est bien réalisée,
                      suite à la prise en compte de ses aspects structurels et
                      mécaniques. Ultérieurement, la vérification périodique de
                      l’état du hauban s’impose : sécurité avant tout !
                    </small>
                  </p>
                </div>
              </div>
            </section>

            <section>
              <StaticImage
                className="image"
                src="../assets/images/pic05.jpg"
                alt=""
              />
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Abattage</h3>
                  </header>
                  <p>
                    L'arbre va tomber, l'homme doit décider.
                    <br />
                    <small className="small">
                      Les questions avant et lors de l’abattage sont
                      primordiales, l’arbre prend des années à se former et
                      prend sa place dans notre environnement au fil des années.
                      Le caractère irréversible de l’abattage exige une
                      réflexion approfondie. La mise en oeuvre d’un abattage en
                      toute sécurité demande une bonne analyse de la situation
                      et des compétences spécifiques. En effet, lorsque les
                      contraintes au sol ou aériennes ne permettent pas une
                      chute en toute sécurité, le démontage de l’arbre sera
                      préféré à l’abattage. La sécurité est assurée par un grand
                      savoir-faire.
                    </small>
                  </p>
                </div>
              </div>
            </section>

            <section>
              <StaticImage
                className="image"
                src="../assets/images/pic14.jpg"
                alt=""
              />
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Evacuation, broyage, rognage</h3>
                  </header>
                  <p style={{ marginBottom: 0 }}>
                    Tâches de nettoyage de chantier
                    <br />
                    <small className="small">
                      Afin de retrouver un environnement propre après les
                      travaux, trois techniques vous sont proposées :
                    </small>
                  </p>
                  <ul className="small">
                    <li>
                      <strong>L’évacuation</strong> en vue d’éliminer tous les
                      rémanants.
                    </li>
                    <li>
                      <strong>Le broyage</strong> de manière à diminuer la
                      quantité du volume au sol et afin de simplifier
                      l’évacuation. Le broyat obtenu peut être utilisé pour
                      l’entretien de zones fleuries ou en compostage pour une
                      amélioration de la structure du sol.
                    </li>
                    <li>
                      <strong>Le rognage</strong> est conseillé lors d’un
                      changement dans la composition paysagère, terrassement,
                      pelouse, replantation... Il consiste à détruire et à
                      enfouir la souche sur place.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
        </main>
      </Layout>
    )
  }
}

export default HomeIndex
